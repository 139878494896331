<template>
  <div>
    <div class="page-name">
      <span>
        <i class="fas fa-map-marker-alt"></i>
        {{i18n('Custom.QuestionBankVersionMaintenance')}}
      </span>
    </div>

    <table class="question-table">
      <tr>
        <th width="33%">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <label style="margin-bottom: 0; flex: 1">
              <input
                type="checkbox"
                v-model="showAllBook"
                @change="changeShowBook"
              />
              {{i18n('Custom.ShowAllVersions')}}
            </label>
            <button class="btn btn-blue mr-2" @click="openBookModal(true)">

              {{i18n('Custom.AddQuestionBankVersion')}}
            </button>
            <button
              v-if="currentBook"
              class="btn btn-sm btn-primary"
              @click="openCloneBookModal()"
            >

              {{i18n('Custom.CloneQuestionBankVersion')}}
            </button>
          </div>
        </th>
        <th width="33%">
          <div class="d-flex justify-content-end mb-2">
            <button
              class="btn btn-blue"
              data-toggle="modal"
              data-target="#editModal"
              @click="openQuestionModal(true, question)"
            >
              {{i18n('Custom.AddTopicOptions')}}
            </button>
          </div>
        </th>
        <th width="33%"></th>
      </tr>
      <tr>
        <td>
          <div class="card" >
            <div class="card-body">
              <table class="table table-striped table-light table-hover">
                <thead>
                  <tr>
                    <th>{{i18n('Basic.Select')}}</th>
                    <th class="text-center">{{i18n('Release.QuestionBankVersion')}}</th>
                    <th class="text-center" width="125">{{i18n('Basic.Edit')}}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="book in bookList" :key="book.Guid">
                    <td>
                      <label class="form-checkbox">
                        <input
                          type="radio"
                          name="book"
                          @click="choiceBook(book)"
                        />
                      </label>
                    </td>
                    <td class="text-center">{{ book.VersionName }}</td>
                    <td class="text-center">
                      <button
                        class="btn btn-sm btn-blue mr-2"
                        @click="openBookModal(false, book)"
                      >
                        {{i18n('Basic.Edit')}}
                      </button>
                      <button
                        class="btn btn-sm btn-danger"
                        @click="openDeleteBookModal(book)"
                      >
                        {{i18n('Basic.Delete')}}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </td>
        <td>
          <div class="card">
            <div class="card-body">
              <table
                class="table table-striped table-light table-hover"
                v-if="questionList.length > 0"
              >
                <thead>
                  <tr>
                    <th>{{i18n('Basic.Select')}}</th>
                    <th>{{i18n('Custom.Topic')}}</th>
                    <th width="125">{{i18n('Basic.Edit')}}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="question in questionList" :key="question.Guid">
                    <td>
                      <label class="form-checkbox">
                        <input
                          type="radio"
                          name="question"
                          @click="choiceQuestion(question)"
                        />
                      </label>
                    </td>
                    <td>{{ question.Question }}</td>
                    <td>
                      <button
                        class="btn btn-sm btn-blue mr-2"
                        data-toggle="modal"
                        data-target="#editBookModal"
                        @click="openQuestionModal(false, question)"
                      >
                        {{i18n('Basic.Edit')}}
                      </button>
                      <button
                        class="btn btn-sm btn-danger"
                        @click="openDeleteQuestionModal(question)"
                      >
                        {{i18n('Basic.Delete')}}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <span v-if="questionList.length === 0">{{i18n('Custom.NoQuestionsYet')}}</span>
            </div>
          </div>
        </td>
        <td>
          <div class="card" v-if="optionList.length > 0">
            <div class="card-body">
              <table class="table table-striped table-light table-hover">
                <thead>
                  <tr>
                    <th>{{i18n('Custom.Options')}}</th>
                    <th>{{i18n('Custom.CorrespondingPersonalityTraits')}}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="option in optionList" :key="option.Guid">
                    <td>{{ option.OptionContent }}</td>
                    <td>
                      <span v-if="option.Personality === 0">D</span>
                      <span v-if="option.Personality === 1">I</span>
                      <span v-if="option.Personality === 2">S</span>
                      <span v-if="option.Personality === 3">C</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <!-- <div class="row">
      <div class="col-md-4">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <label style="margin-bottom:0; flex:1;">
            <input type="checkbox" v-model="showAllBook" @change="changeShowBook">
            顯示全部題庫版本
          </label>
          <button
            class="btn btn-blue mr-2"
            @click="openBookModal(true)"
          >
            新增題庫版本
          </button>
           <button
           v-if="currentBook"
            class="btn btn-sm btn-primary"
            @click="openCloneBookModal()"
          >
            複製題庫版本
          </button>
        </div>

        <div class="card">
          <div class="card-body">
            <table class="table table-striped table-light table-hover">
              <thead>
                <tr>
                  <th>選擇</th>
                  <th class="text-center">題庫版本</th>
                  <th class="text-center">編輯</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="book in bookList" :key="book.Guid">
                  <td>
                    <label class="form-checkbox">
                      <input type="radio" name="book" @click="choiceBook(book)" />
                    </label>
                  </td>
                  <td class="text-center">{{ book.VersionName }}</td>
                  <td class="text-center">
                    <button
                      class="btn btn-sm btn-blue mr-2"
                      @click="openBookModal(false, book)"
                    >
                      修改
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      @click="openDeleteBookModal(book)"
                    >
                      刪除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-4 right" v-if="currentBook">
        <div class="d-flex justify-content-end mb-2">
          <button
            class="btn btn-blue"
            data-toggle="modal"
            data-target="#editModal"
            @click="openQuestionModal(true,question)"
          >
            新增題目選項
          </button>
        </div>
        <div class="card">
          <div class="card-body">
            <table
              class="table table-striped table-light table-hover"
              v-if="questionList.length > 0"
            >
              <thead>
                <tr>
                  <th>選擇</th>
                  <th>題目</th>
                  <th>編輯</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="question in questionList" :key="question.Guid">
                  <td>
                    <label class="form-checkbox">
                      <input type="radio" name="question" @click="choiceQuestion(question)" />
                    </label>
                  </td>
                  <td>{{ question.Question }}</td>
                  <td>
                    <button
                      class="btn btn-sm btn-blue mr-2"
                      data-toggle="modal"
                      data-target="#editBookModal"
                      @click="openQuestionModal(false, question)"
                    >
                      修改
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      @click="openDeleteQuestionModal(question)"
                    >
                      刪除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <span v-if="questionList.length === 0">尚無題目</span>
          </div>
        </div>
      </div>

      <div class="col-md-4 right" v-if="optionList.length > 0">
        <div class="card">
          <div class="card-body">
            <table class="table table-striped table-light table-hover">
              <thead>
                <tr>
                  <th>選項</th>
                  <th>對應人格特質</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="option in optionList" :key="option.Guid">
                  <td>{{ option.OptionContent }}</td>
                  <td>
                    <span v-if="option.Personality === 0">D</span>
                    <span v-if="option.Personality === 1">I</span>
                    <span v-if="option.Personality === 2">S</span>
                    <span v-if="option.Personality === 3">C</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div> -->

    <!-- 題庫版本modal -->
    <div class="modal fade" id="bookModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <el-form :model="bookModal" ref="bookModal" class="modal-content">
          <div class="modal-header">
            <span v-if="bookIsNew">{{i18n('Basic.Add')}}</span
            ><span v-if="!bookIsNew">{{i18n('Basic.Edit')}}</span> {{i18n('Release.QuestionBankVersion')}}
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <el-form-item
                  :label="i18n('Release.QuestionBankVersion')"
                  prop="VersionName"
                  :rules="{
                    required: true,
                    message: '必填',
                    trigger: ['blur', 'change'],
                  }"
                >
                  <el-input v-model="bookModal.VersionName"></el-input>
                </el-form-item>
              </div>
              <div class="col-md-12">
                <el-form-item
                  :label="i18n('Basic.Enable')"
                  prop="IsEnabled"
                  :rules="{
                    required: true,
                    message: '必填',
                    trigger: ['blur', 'change'],
                  }"
                >
                  <el-switch v-model="bookModal.IsEnabled"></el-switch>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-blue" @click.prevent="saveBook">
              {{i18n('Basic.Save')}}
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{i18n('Basic.Cancel')}}
            </button>
          </div>
        </el-form>
      </div>
    </div>

    <!-- 複製題庫modal -->
    <div class="modal fade" id="cloneModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <el-form :model="cloneModal" ref="cloneModal" class="modal-content">
          <div class="modal-header">
            {{i18n('Custom.CloneQuestionBankVersion')}}
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <el-form-item :label="i18n('Custom.OldQuestionBankVersion')">
                  <el-input
                    :disabled="true"
                    v-model="cloneModal.VersionName"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="col-md-12">
                <el-form-item
                  :label="i18n('Custom.NewQuestionBankName')"
                  prop="NewVersionName"
                  :rules="{
                    required: true,
                    message: '必填',
                    trigger: ['blur', 'change'],
                  }"
                >
                  <el-input v-model="cloneModal.NewVersionName"></el-input>
                </el-form-item>
              </div>
              <div class="col-md-12">
                <el-form-item
                  :label="i18n('Basic.Enable')"
                  prop="IsEnabled"
                  :rules="{
                    required: true,
                    message: '必填',
                    trigger: ['blur', 'change'],
                  }"
                >
                  <el-switch v-model="cloneModal.IsEnabled"></el-switch>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-blue" @click.prevent="saveClone">
              {{i18n('Basic.Save')}}
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{i18n('Basic.Cancel')}}
            </button>
          </div>
        </el-form>
      </div>
    </div>

    <!-- 題目選項modal -->
    <div class="modal fade" id="questionModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <el-form
          label-position="top"
          :model="questionModal"
          ref="questionModal"
          class="modal-content"
        >
          <div class="modal-header">
            <span v-if="questionIsNew">{{i18n('Basic.Add')}}</span
            ><span v-if="!questionIsNew">{{i18n('Basic.Edit')}}</span> {{i18n('Custom.TopicOptions')}}
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <el-form-item
                  :label="i18n('Custom.Topic')"
                  prop="Question"
                  :rules="{
                    required: true,
                    message: '必填',
                    trigger: ['blur', 'change'],
                  }"
                >
                  <el-input v-model="questionModal.Question"></el-input>
                </el-form-item>
              </div>
              <div class="col-md-8">
                <el-form-item
                  :label="i18n('Custom.Options')"
                  :prop="'Options.' + 0 + '.OptionContent'"
                  :rules="{
                    required: true,
                    message: '必填',
                    trigger: ['blur', 'change'],
                  }"
                >
                  <el-input
                    v-model="questionModal.Options[0].OptionContent"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="col-md-4">
                <el-form-item
                  :label="i18n('Custom.CorrespondingPersonalityTraits')"
                  :prop="'Options.' + 0 + '.Personality'"
                  :rules="{
                    required: true,
                    message: '必填',
                    trigger: ['blur', 'change'],
                  }"
                >
                  <el-radio-group
                    v-model="questionModal.Options[0].Personality"
                  >
                    <el-radio :label="0">D</el-radio>
                    <el-radio :label="1">I</el-radio>
                    <el-radio :label="2">S</el-radio>
                    <el-radio :label="3">C</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>

              <div class="col-md-8">
                <el-form-item
                  :prop="'Options.' + 1 + '.OptionContent'"
                  :rules="{
                    required: true,
                    message: '必填',
                    trigger: ['blur', 'change'],
                  }"
                >
                  <el-input
                    v-model="questionModal.Options[1].OptionContent"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="col-md-4">
                <el-form-item
                  :prop="'Options.' + 1 + '.Personality'"
                  :rules="{
                    required: true,
                    message: '必填',
                    trigger: ['blur', 'change'],
                  }"
                >
                  <el-radio-group
                    v-model="questionModal.Options[1].Personality"
                  >
                    <el-radio :label="0">D</el-radio>
                    <el-radio :label="1">I</el-radio>
                    <el-radio :label="2">S</el-radio>
                    <el-radio :label="3">C</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>

              <div class="col-md-8">
                <el-form-item
                  :prop="'Options.' + 2 + '.OptionContent'"
                  :rules="{
                    required: true,
                    message: '必填',
                    trigger: ['blur', 'change'],
                  }"
                >
                  <el-input
                    v-model="questionModal.Options[2].OptionContent"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="col-md-4">
                <el-form-item
                  :prop="'Options.' + 2 + '.Personality'"
                  :rules="{
                    required: true,
                    message: '必填',
                    trigger: ['blur', 'change'],
                  }"
                >
                  <el-radio-group
                    v-model="questionModal.Options[2].Personality"
                  >
                    <el-radio :label="0">D</el-radio>
                    <el-radio :label="1">I</el-radio>
                    <el-radio :label="2">S</el-radio>
                    <el-radio :label="3">C</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div class="col-md-8">
                <el-form-item
                  :prop="'Options.' + 3 + '.OptionContent'"
                  :rules="{
                    required: true,
                    message: '必填',
                    trigger: ['blur', 'change'],
                  }"
                >
                  <el-input
                    v-model="questionModal.Options[3].OptionContent"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="col-md-4">
                <el-form-item
                  :prop="'Options.3.Personality'"
                  :rules="{
                    required: true,
                    message: '必填',
                    trigger: ['blur', 'change'],
                  }"
                >
                  <el-radio-group
                    v-model="questionModal.Options[3].Personality"
                  >
                    <el-radio :label="0">D</el-radio>
                    <el-radio :label="1">I</el-radio>
                    <el-radio :label="2">S</el-radio>
                    <el-radio :label="3">C</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-blue" @click.prevent="saveQuestion()">
              {{i18n('Basic.Save')}}
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{i18n('Basic.Cancel')}}
            </button>
          </div>
        </el-form>
      </div>
    </div>

    <!-- 刪除題庫modal -->
    <div class="modal fade" id="deleteBookModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">{{i18n('Custom.DeleteQuestionBank')}}</div>

          <div class="modal-body">
            <div class="alert-box">
              <div class="alert-icon">!</div>
              {{i18n('Custom.WhetherDeleteItem')}}
              <span v-if="deleteItem">{{ deleteItem.VersionName }}</span>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{i18n('Basic.Cancel')}}
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="deleteBook"
            >
              {{i18n('Basic.Delete')}}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 刪除題目modal -->
    <div
      class="modal fade"
      id="deleteQuestionModal"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">{{i18n('Custom.DeleteTopic')}}</div>

          <div class="modal-body">
            <div class="alert-box">
              <div class="alert-icon">!</div>
              {{i18n('Custom.WhetherDeleteItem')}} <span v-if="deleteItem">{{ deleteItem.Question }}</span>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{i18n('Basic.Cancel')}}
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="deleteQuestion"
            >
              {{i18n('Basic.Delete')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  data() {
    return {
      showAllBook: false,
      screen: null, // loading遮罩
      bookIsNew: true,
      questionIsNew: true,
      bookList: [], // 過濾後的版本
      tempBookList: [], // 查出來所有的版本
      questionList: [],
      optionList: [],
      cloneModal: {
        OldVersionId: null,
        NewVersionName: null,
        IsEnabled: false,
      },
      bookModal: {
        IsEnabled: false,
      },
      questionModal: {
        Options: [
          {
            SeqNo: 1,
            OptionContent: null,
            Personality: null,
          },
          {
            SeqNo: 2,
            OptionContent: null,
            Personality: null,
          },
          {
            SeqNo: 3,
            OptionContent: null,
            Personality: null,
          },
          {
            SeqNo: 4,
            OptionContent: null,
            Personality: null,
          },
        ],
      },
      currentBook: null,
      currentQuestion: {},
      deleteItem: null,
    };
  },
  methods: {
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },
    changeShowBook() {
      if (this.showAllBook) {
        this.bookList = this.tempBookList.filter((item) => item);
      } else {
        this.bookList = this.tempBookList.filter(
          (item) => item.IsEnabled === true,
        );
      }
    },
    getBookList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Ver`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: '錯誤',
              message: response.data.ErrorMessage,
              type: 'error',
              duration: 0,
            });
          } else {
            this.tempBookList = response.data.Data;
            this.changeShowBook();
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: '錯誤',
            message: error.response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
          this.screen.close();
        });
    },
    getQuestionList(bookId) {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Ver/Question/${bookId}`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: '錯誤',
              message: response.data.ErrorMessage,
              type: 'error',
              duration: 0,
            });
          } else {
            this.questionList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: '錯誤',
            message: error.response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
          this.screen.close();
        });
    },
    openBookModal(isNew, book) {
      this.bookIsNew = isNew;
      if (!isNew) {
        this.bookModal = { ...book };
      } else {
        this.bookModal = {
          IsEnabled: false,
        };
      }
      $('#bookModal').modal('show');

      this.$nextTick(() => {
        this.$refs.bookModal.clearValidate();
      });
    },

    openCloneBookModal() {
      this.cloneModal = {
        VersionName: this.currentBook.VersionName,
        OldVersionId: this.currentBook.Guid,
        NewVersionName: null,
        IsEnabled: false,
      };
      $('#cloneModal').modal('show');

      this.$nextTick(() => {
        this.$refs.bookModal.clearValidate();
      });
    },
    saveClone() {
      // eslint-disable-next-line consistent-return
      this.$refs.cloneModal.validate((valid) => {
        if (valid) {
          const vm = this;
          const data = { ...this.cloneModal };
          vm.screen = this.openLoading();
          const httpMethod = 'post';
          const api = `${window.BaseUrl.api}/Eva/Ver/Copy`;

          vm.$http[httpMethod](api, data).then((response) => {
            if (response.data.ErrorMessage) {
              this.$notify({
                title: '錯誤',
                message: response.data.ErrorMessage,
                type: 'error',
                duration: 0,
              });
            } else {
              this.getBookList();
              $('#cloneModal').modal('hide');
              this.$store.dispatch('alertMessageModule/updateMessage', {
                message: '複製成功',
                status: 'success',
              });
            }

            this.screen.close();
          });
        } else {
          return false;
        }
      });
    },

    saveBook() {
      // eslint-disable-next-line consistent-return
      this.$refs.bookModal.validate((valid) => {
        if (valid) {
          const vm = this;
          const data = { ...this.bookModal };
          vm.screen = this.openLoading();
          const httpMethod = vm.bookIsNew ? 'post' : 'put';
          const api = vm.bookIsNew
            ? `${window.BaseUrl.api}/Eva/Ver`
            : `${window.BaseUrl.api}/Eva/Ver/${vm.bookModal.Guid}`;

          vm.$http[httpMethod](api, data)
            .then((response) => {
              if (response.data.ErrorMessage) {
                this.$notify({
                  title: '錯誤',
                  message: response.data.ErrorMessage,
                  type: 'error',
                  duration: 0,
                });
              } else {
                this.getBookList();
                $('#bookModal').modal('hide');
                this.$store.dispatch('alertMessageModule/updateMessage', {
                  message: vm.bookIsNew ? '新增成功' : '編輯成功',
                  status: 'success',
                });
              }

              this.screen.close();
            })
            .catch((error) => {
              this.$notify({
                title: '錯誤',
                message: error.response.data.ErrorMessage,
                type: 'error',
                duration: 0,
              });
              this.screen.close();
            });
        } else {
          return false;
        }
      });
    },
    choiceBook(book) {
      this.currentBook = book;
      this.getQuestionList(book.Guid);
    },
    choiceQuestion(question) {
      this.currentQuestion = question;
      this.optionList = question.Options;
    },

    openQuestionModal(isNew, question) {
      this.questionIsNew = isNew;
      if (!isNew) {
        // this.questionModal = { ...question };
        // this.questionModal = JSON.parse(JSON.stringify({ ...question }));
        const temp = JSON.parse(JSON.stringify(question));
        this.questionModal = temp;
      } else {
        this.questionModal = {
          Options: [
            {
              SeqNo: 1,
              OptionContent: null,
              Personality: null,
            },
            {
              SeqNo: 2,
              OptionContent: null,
              Personality: null,
            },
            {
              SeqNo: 3,
              OptionContent: null,
              Personality: null,
            },
            {
              SeqNo: 4,
              OptionContent: null,
              Personality: null,
            },
          ],
        };
      }
      $('#questionModal').modal('show');
      this.$nextTick(() => {
        this.$refs.questionModal.clearValidate();
      });
    },
    saveQuestion() {
      // eslint-disable-next-line consistent-return
      this.$refs.questionModal.validate((valid) => {
        if (valid) {
          const vm = this;
          const data = { ...vm.questionModal };
          vm.screen = this.openLoading();
          const httpMethod = vm.questionIsNew ? 'post' : 'put';
          const api = vm.questionIsNew
            ? `${window.BaseUrl.api}/Eva/Ver/Question/${vm.currentBook.Guid}`
            : `${window.BaseUrl.api}/Eva/Ver/Question/${vm.currentBook.Guid}/${vm.questionModal.Guid}`;

          vm.$http[httpMethod](api, data).then((response) => {
            if (response.data.ErrorMessage) {
              this.$notify({
                title: '錯誤',
                message: response.data.ErrorMessage,
                type: 'error',
                duration: 0,
              });
            } else {
              this.getQuestionList(this.currentBook.Guid);
              $('#questionModal').modal('hide');
              this.$store.dispatch('alertMessageModule/updateMessage', {
                message: vm.questionIsNew ? '新增成功' : '編輯成功',
                status: 'success',
              });
            }
            this.screen.close();
          })
            .catch((error) => {
              this.$notify({
                title: '錯誤',
                message: error.response.data.ErrorMessage,
                type: 'error',
                duration: 0,
              });
              this.screen.close();
            });
        } else {
          return false;
        }
      });
    },

    openDeleteBookModal(item) {
      $('#deleteBookModal').modal('show');
      this.deleteItem = { ...item };
    },
    openDeleteQuestionModal(item) {
      $('#deleteQuestionModal').modal('show');
      this.deleteItem = { ...item };
    },
    deleteBook() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Ver/${this.deleteItem.Guid}`;

      this.$http.delete(url).then((response) => {
        if (response.data.ErrorMessage) {
          this.$notify({
            title: '錯誤',
            message: response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
        } else {
          this.questionList = [];
          this.currentBook = null;
          this.getBookList();
          this.$store.dispatch('alertMessageModule/updateMessage', {
            message: '刪除成功',
            status: 'success',
          });
        }
        this.screen.close();
      })
        .catch((error) => {
          this.$notify({
            title: '錯誤',
            message: error.response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
          this.screen.close();
        });
    },

    deleteQuestion() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Ver/Question/${this.currentBook.Guid}/${this.deleteItem.Guid}`;

      this.$http.delete(url).then((response) => {
        if (response.data.ErrorMessage) {
          this.$notify({
            title: '錯誤',
            message: response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
        } else {
          this.getQuestionList(this.currentBook.Guid);
          this.$store.dispatch('alertMessageModule/updateMessage', {
            message: '刪除成功',
            status: 'success',
          });
        }
        this.screen.close();
      }).catch((error) => {
        this.$notify({
          title: '錯誤',
          message: error.response.data.ErrorMessage,
          type: 'error',
          duration: 0,
        });
        this.screen.close();
      });
    },
  },
  created() {
    this.getBookList();
  },
};
</script>

<style lang="scss" scoped>
.question-table {
  width: 100%;
  td {
    vertical-align: top;
  }
}
</style>
